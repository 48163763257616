<template>
  <div>
      <Card :title="'Exemplo View'">
          exemplo de view
      </Card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>